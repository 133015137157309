import { useState, useEffect, useRef } from "react";
import { Statistics } from "./Statistics.js";

export const fetchData = async (href, token) => {
    const data = await fetch(href, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    return data.json();
};

export const PlaylistSelector = (props) => {
    const [loading, setLoading] = useState("loading...");
    const [list, setList] = useState();
    const [selectedPlaylist, setSelectedPlaylist] = useState();
    const playlistInput = useRef();

    // get playlists from your library
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData("https://api.spotify.com/v1/me/playlists", props.token).then(({ items }) => {
            if (items) setList(items);
            else setLoading("Couldn't load your library");
        });
    }, [props.token]);

    // checks the format of a playlist URL
    const validatePlaylist = (event) => {
        event.preventDefault();

        const match = playlistInput.current.value.match(/((https:\/\/)?open.spotify.com\/playlist\/)?([\w]{22})/);

        if (match) setSelectedPlaylist(match[3]);
        else
            alert(
                "Enter a valid Spotify playlist URL or select one from your library\nTo find the link, go to the playlist on Spotify -> Share -> Copy link"
            );
    };

    // loading
    if (!list) {
        return (
            <div className="container">
                <div className="navigation">{props.children}</div>
                <p className="loading">{loading}</p>
            </div>
        );
    }

    const Playlist = (props) => (
        <button className="playlist" onClick={() => setSelectedPlaylist(props.p.id)}>
            <img className="playlist-image" src={props.p.images[0]?.url} alt=""></img>
            <p className="playlist-title">{props.p.name}</p>
        </button>
    );

    // selection page
    if (!selectedPlaylist) {
        return (
            <div className="container">
                <div className="navigation">{props.children}</div>

                <div className="selection">
                    Enter a playlist link:
                    <form onSubmit={validatePlaylist} className="form">
                        <input ref={playlistInput} placeholder="Playlist URL" className="input"></input>
                        <button type="submit" className="go">
                            Go
                        </button>
                    </form>
                    or select a playlist from your library:
                    <div className="library">
                        {list.map((playlist) => (
                            <Playlist p={playlist} key={playlist.id}></Playlist>
                        ))}
                        <div className="playlist-hidden"></div>
                        <div className="playlist-hidden"></div>
                    </div>
                </div>
            </div>
        );
    }

    // stats page
    return (
        <div className="container">
            <div className="navigation">
                <button onClick={() => setSelectedPlaylist(undefined)} className="navbutton">
                    Back
                </button>
                {props.children}
            </div>

            <Statistics id={selectedPlaylist} token={props.token}></Statistics>
        </div>
    );
};
