// from accepted response: https://stackoverflow.com/questions/18230217/javascript-generate-a-random-number-within-a-range-using-crypto-getrandomvalues
const getRandomInt = (min, max) => {
    const byteArray = new Uint8Array(1);
    crypto.getRandomValues(byteArray);

    const range = max - min + 1;
    const max_range = 256;
    if (byteArray[0] >= Math.floor(max_range / range) * range) return getRandomInt(min, max);
    return min + (byteArray[0] % range);
};

// from: https://jameshfisher.com/2017/10/30/web-cryptography-api-hello-world/
const sha256 = async (str) => {
    const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
    return Array.prototype.map.call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2)).join("");
};

export const getLoginURL = async () => {
    const clientID = "823fc42a614a468ca93e5912d87e8aa7";

    const possibilities = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-.~";

    const n = getRandomInt(43, 128);
    let randomString = "";
    for (let i = 0; i < n; i++) {
        randomString += possibilities.charAt(getRandomInt(0, possibilities.length - 1));
    }

    const hash = await sha256(randomString);
    const scopes = "user-read-private%20user-read-email%20playlist-read-private%20playlist-read-collaborative";

    const loginURL =
        "https://accounts.spotify.com/authorize?client_id=" +
        clientID +
        "&redirect_uri=http:%2F%2Fplayliststatistics.com&scope=" +
        scopes +
        "&response_type=token&state=" +
        hash +
        "&show_dialog=true";

    window.localStorage.setItem("authentication state", hash);

    return loginURL;
};
