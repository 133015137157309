import { useState, useEffect } from "react";
import { fetchData } from "./Selection.js";

export const Statistics = (props) => {
    const [stats, setStats] = useState();
    const [loading, setLoading] = useState("loading...");
    const [includeFeatures, setIncludeFeatures] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        computeStatistics(props.id, props.token).then((computedStatistics) => {
            if (mounted) {
                if (typeof computedStatistics === "string")
                    setLoading(computedStatistics);
                else setStats(computedStatistics);
            }
        });
        return () => setMounted(false);
    }, [props.id, props.token, mounted]);

    if (!stats) return <p className="loading">{loading}</p>;

    const artists = includeFeatures ? stats.artistsFt : stats.artistsNoFt;
    const formatPercentage = (percentage, digits) =>
        `${percentage.toFixed(digits)}%`;
    const formatPair = (pair, text, digits) =>
        pair.total + text + `(${formatPercentage(pair.percentage, digits)})`;
    const formatAverageDuration = (totalSeconds) => {
        const seconds = "0" + Math.round(totalSeconds % 60);
        return (
            Math.floor(totalSeconds / 60) +
            ":" +
            seconds.substring(seconds.length - 2)
        );
    };
    const formatTotalDuration = (totalSeconds) => {
        totalSeconds = Math.round(totalSeconds);
        if (totalSeconds < 3600) {
            const totalMinutes = Math.floor(totalSeconds / 60);
            const remainingSeconds = totalSeconds % 60;
            return totalMinutes + " min " + remainingSeconds + " sec";
        } else {
            const totalHours = Math.floor(totalSeconds / 3600);
            const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
            return totalHours + " hr " + remainingMinutes + " min";
        }
    };
    const getPlural = (n) => (n === 1 ? "" : "s");

    return (
        <div className="stats">
            <header className="stats-header">
                <div className="stats-details">
                    <SpotifyImg
                        src={stats.picture}
                        url={stats.url}
                        className="stats-img"
                    ></SpotifyImg>
                    <div className="stats-info">
                        <p
                            onClick={() => open(stats.url)}
                            className="stats-title"
                        >
                            {stats.name}
                        </p>
                        <p className="stats-owner">By {stats.owner}</p>
                        <p className="fact">
                            {stats.nTracks} song{getPlural(stats.nTracks)}
                        </p>
                        <p className="fact">
                            {formatTotalDuration(stats.totalDuration)}
                        </p>
                    </div>
                </div>
                <ul className="facts">
                    <div className="fact-col">
                        <li className="fact">
                            {formatPair(
                                stats.areExplicit,
                                ` song${
                                    stats.areExplicit.total === 1
                                        ? " is"
                                        : "s are"
                                } explicit `,
                                0
                            )}
                        </li>
                        <li className="fact">
                            {formatPair(
                                stats.haveFeatures,
                                ` song${
                                    stats.haveFeatures.total === 1
                                        ? " has a feature"
                                        : "s have features"
                                } `,
                                0
                            )}
                        </li>
                        <li className="fact">
                            {formatAverageDuration(
                                stats.totalDuration / stats.nTracks
                            )}{" "}
                            average song length
                        </li>
                    </div>

                    <div className="fact-col">
                        <li className="fact">
                            {includeFeatures
                                ? stats.nArtistsFt
                                : stats.nArtistsNoFt}{" "}
                            unique artist
                            {getPlural(
                                includeFeatures
                                    ? stats.nArtistsFt
                                    : stats.nArtistsNoFt
                            )}
                        </li>
                        <li className="fact">
                            {stats.artistsPerSong.toFixed(2)} artist
                            {getPlural(stats.artistsPerSong)} per song
                        </li>
                    </div>

                    <div className="fact-col">
                        <li className="fact tooltip">
                            {formatPercentage(stats.popularity, 0)} popular
                        </li>
                        <li className="fact">
                            {formatPercentage(stats.diversity, 0)} diverse
                        </li>
                    </div>
                </ul>
            </header>

            <div className="stats-container">
                <div className="tracks">
                    <div className="track-group">
                        <div className="track-col">
                            <div className="stats-subheading main-color">
                                Most popular songs:
                            </div>
                            {stats.tracks
                                .slice(0, 5)
                                .map((item) =>
                                    item.track.id ? (
                                        <Track
                                            t={item.track}
                                            key={item.track.id}
                                        ></Track>
                                    ) : null
                                )}
                        </div>
                        <div className="track-col">
                            <div className="stats-subheading main-color">
                                Least popular songs:
                            </div>
                            {stats.tracks
                                .slice(-5)
                                .reverse()
                                .map((item) =>
                                    item.track.id ? (
                                        <Track
                                            t={item.track}
                                            key={item.track.id}
                                        ></Track>
                                    ) : null
                                )}
                        </div>
                    </div>

                    <div className="track-group">
                        <div className="track-col">
                            <div className="stats-subheading main-color">
                                Newest songs:
                            </div>
                            {stats.dates
                                .slice(0, 5)
                                .map((item) =>
                                    item.track.id ? (
                                        <Track
                                            t={item.track}
                                            key={item.track.id}
                                        ></Track>
                                    ) : null
                                )}
                        </div>
                        <div className="track-col">
                            <div className="stats-subheading main-color">
                                Oldest songs:
                            </div>
                            {stats.dates
                                .slice(-5)
                                .reverse()
                                .map((item) =>
                                    item.track.id ? (
                                        <Track
                                            t={item.track}
                                            key={item.track.id}
                                        ></Track>
                                    ) : null
                                )}
                        </div>
                    </div>
                </div>

                <div className="artists">
                    <div className="stats-subheading main-color">
                        Top artists:
                    </div>

                    {artists.length > 5 ? (
                        <button
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className="artists-toggle"
                        >
                            {expanded
                                ? "Show top 5 artists"
                                : "Show top 30 artists"}
                        </button>
                    ) : null}

                    <div>
                        <label className="include-features">
                            <input
                                className="include-features"
                                type="checkbox"
                                checked={includeFeatures}
                                onChange={() => {
                                    setIncludeFeatures(!includeFeatures);
                                }}
                            ></input>
                            Include features
                        </label>
                    </div>

                    {(expanded ? artists : artists.slice(0, 5)).map(
                        (artist, i) => (
                            <div key={artist.name} className="artist">
                                <SpotifyImg
                                    src={artist.img}
                                    url={artist.url}
                                    className="artist-img"
                                ></SpotifyImg>
                                <p>
                                    {i + 1 + ". " + artist.name} -{" "}
                                    {formatPair(
                                        artist.pair,
                                        ` song${getPlural(artist.pair.total)} `,
                                        2
                                    )}
                                </p>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

const computeStatistics = async (id, token) => {
    try {
        const data = await fetchData(
            `https://api.spotify.com/v1/playlists/${id}`,
            token
        );

        if (data.error) return "Couldn't load playlist";
        if (data.tracks.total === 0) return "Playlist is empty";

        let group = data.tracks;
        let tracks = group.items;
        while (group.next) {
            group = await fetchData(group.next, token);
            tracks = tracks.concat(group.items);
        }

        tracks = tracks.filter((item) => item.track);

        const [
            totalDuration,
            totalExplicit,
            totalWithFeatures,
            totalArtists,
            totalPopularity,
        ] = tracks.reduce((totals, item) => {
            totals[0] += item.track.duration_ms;
            totals[1] += item.track.explicit;
            totals[2] += item.track.artists.length !== 1;
            totals[3] += item.track.artists.length;
            totals[4] += item.track.popularity;
            return totals;
        }, new Array(5).fill(0));

        const artistsFt = {};
        const artistsNoFt = {};
        tracks.forEach((item) => {
            const artists = item.track.artists;
            artists.forEach((artist) => {
                artistsFt[artist.id] = artistsFt[artist.id] + 1 || 1;
            });

            artistsNoFt[artists[0].id] = artistsNoFt[artists[0].id] + 1 || 1;
        });

        const getPair = (n) => {
            return { total: n, percentage: (n / data.tracks.total) * 100 };
        };

        const artistsToSortedArray = (artistsObj) =>
            Object.entries(artistsObj).sort((a, b) => b[1] - a[1]);

        const artistsNoFtArray = artistsToSortedArray(artistsNoFt);
        const artistsFtArray = artistsToSortedArray(artistsFt);

        const fetchArtists = async (artistsArray) =>
            Promise.all(
                artistsArray
                    .filter((entry) => entry[0] !== "null")
                    .slice(0, 30)
                    .map(async (entry) => {
                        const artist = await fetchData(
                            `https://api.spotify.com/v1/artists/${entry[0]}`,
                            token
                        );
                        return {
                            img: artist.images[0]?.url,
                            url: artist.external_urls.spotify,
                            name: artist.name,
                            pair: getPair(entry[1]),
                        };
                    })
            );

        tracks = tracks.filter(({ track }) => !track.is_local);

        return {
            name: data.name,
            owner: data.owner.display_name,
            nTracks: data.tracks.total,
            tracks: tracks.sort(
                (a, b) => b.track.popularity - a.track.popularity
            ),
            dates: tracks
                .slice()
                .sort((a, b) =>
                    b.track.album.release_date?.localeCompare(
                        a.track.album.release_date
                    )
                ),
            picture: data.images[0]?.url,
            url: data.external_urls.spotify,
            totalDuration: totalDuration / 1000,
            areExplicit: getPair(totalExplicit),
            haveFeatures: getPair(totalWithFeatures),
            artistsPerSong: totalArtists / data.tracks.total,
            nArtistsFt: artistsFtArray.length,
            nArtistsNoFt: artistsNoFtArray.length,
            artistsFt: await fetchArtists(artistsFtArray),
            artistsNoFt: await fetchArtists(artistsNoFtArray),
            diversity: (artistsNoFtArray.length / data.tracks.total) * 100,
            popularity: totalPopularity / data.tracks.total,
        };
    } catch (err) {
        return "Couldn't load playlist";
    }
};

const Track = (props) => (
    <div className="track">
        <SpotifyImg
            src={props.t.album.images[0].url}
            url={props.t.external_urls.spotify}
            className="track-img"
        ></SpotifyImg>
        <p>{props.t.name}</p>
    </div>
);

const open = (url) => window.open(url, "_blank");

const SpotifyImg = (props) => (
    <img
        src={props.src}
        alt=""
        onClick={() => {
            open(props.url);
        }}
        className={props.className}
    ></img>
);
