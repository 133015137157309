import { useEffect } from "react";
import { getLoginURL } from "./Authentication.js";
import { PlaylistSelector } from "./Selection.js";

export const App = () => {
    useEffect(() => {
        window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }, []);

    if (!window.location.hash) return loginPage;

    // 0 = access_token, 1 = token_type, 2 = expires_in, 3 = state
    const fragments = window.location.hash
        .substring(1)
        .split("&")
        .map((fragment) => fragment.substring(1 + fragment.indexOf("=")));

    if (!fragments[3] || fragments[3] !== window.localStorage.getItem("authentication state")) return loginPage;

    return (
        <PlaylistSelector token={fragments[0]}>
            <button onClick={logout} className="navbutton logout">
                Logout
            </button>
        </PlaylistSelector>
    );
};

const login = async () => {
    const url = await getLoginURL();
    window.location.replace(url);
};

const logout = () => {
    window.location.replace("http://playliststatistics.com");
};

const loginPage = (
    <div className="container login-container">
        <header className="login-section">
            <h1 className="main-color">Playlist Statistics</h1>
            <p className="about">See interesting stats about your favorite Spotify playlists</p>
            <button onClick={login} className="login">
                Login with Spotify
            </button>
        </header>
        <a href="mailto:matthewrosser0@gmail.com" className="contact main-color">
            Contact
        </a>
    </div>
);
